import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    IconButton,
    Typography,
    Alert
} from '@mui/material';
import { TableCell, TableRow } from '../../components/shared/styles/Table';
import { Transaction, TransactionStatus } from '../../models/transaction';
import { PlayIcon } from '../../icons/PlayIcon';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { formatDate, useFormatting } from '../../utils/formatting';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/user/UserContext';
import styled from '@emotion/styled';
import Chip from '../../components/shared/Chip';
import { FONT_WEIGHT, TEXT_SIZE } from '../../typography/Typography';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import {
    postAiiaRefreshPayment,
    getAiiaRetryAuthorizeUrl,
    setAiiaRetryErrorHandled
} from '../../lib/slices/paymentsSlice';
import { FETCH_STATE } from '../../lib/slices/types';
import { useTranslation } from 'react-i18next';
import {
    paymentSources,
    paymentProviders,
    approvalTypes
} from '../../utils/constant';
import { useNavigate } from 'react-router-dom';

export type TransactionsListProps = {
    companyPaymentDetails: CompanyPaymentDetailsResponse;
    transactions: Array<Transaction>;
    reloadTransactions: () => void;
};

const TableHeader = styled(TableHead)`
    background-color: #f9fafb;
    border-top: 1px solid rgba(224, 224, 224, 1);
    th {
        padding: 5px 8px 5px 24px;
        font-weight: 600;
    }

    tr {
        border-bottom: 1px solid #d0d5dd;
    }
`;

export const TransactionsList = (props: TransactionsListProps) => {
    const { t } = useTranslation();
    const { setLoading } = useLoader();
    const { setAlert } = useNotificationsSnackbar();
    const dispatch = useAppDispatch();
    const {
        aiiaRefreshPayment,
        aiiaRetryAuthorize,
        aiiaRegenerateAuthorizeUrl
    } = usePayments();

    const getStatusColor = (status: TransactionStatus) => {
        switch (status) {
            case TransactionStatus.PendingApproval:
                return 'info';
            case TransactionStatus.AwaitingBank:
            case TransactionStatus.AwaitingDeposit:
            case TransactionStatus.ActionInProgress:
            case TransactionStatus.Authorized:
                return 'warning';
            case TransactionStatus.Succeeded:
                return 'success';
            case TransactionStatus.Failed:
                return 'error';
            case TransactionStatus.Rejected:
                return 'error';
            case TransactionStatus.Expired:
                return 'error';
        }
    };

    const getDateColor = (status: TransactionStatus) => {
        switch (status) {
            case TransactionStatus.AwaitingBank:
            case TransactionStatus.AwaitingDeposit:
            case TransactionStatus.ActionInProgress:
            case TransactionStatus.Authorized:
                return '#DC6802';
        }
    };

    useEffect(() => {
        setLoading(aiiaRefreshPayment.status == FETCH_STATE.LOADING);
        if (aiiaRefreshPayment.status == FETCH_STATE.SUCCEEDED) {
            setAlert(t('status_refreshed'), 'success');
        } else if (aiiaRefreshPayment.status == FETCH_STATE.FAILED) {
            setAlert(t('status_refresh_failed'), 'error');
        }
    }, [aiiaRefreshPayment.status]);

    const onRefreshClick = async (transactionId: string) => {
        setLoading(true);
        dispatch(postAiiaRefreshPayment(transactionId));
        props.reloadTransactions();
    };

    useEffect(() => {
        setLoading(aiiaRetryAuthorize.status == FETCH_STATE.LOADING);
        if (aiiaRetryAuthorize.status == FETCH_STATE.SUCCEEDED) {
            setAlert(t('success_retry'), 'success');
        } else if (aiiaRetryAuthorize.status == FETCH_STATE.FAILED) {
            setAlert(t('transaction_retry_failed'), 'error');
            dispatch(setAiiaRetryErrorHandled());
        }
    }, [aiiaRetryAuthorize.status]);

    const onTryAgainClick = async (transactionId: string) => {
        setLoading(true);
        dispatch(getAiiaRetryAuthorizeUrl({ transactionId: transactionId }));
        props.reloadTransactions();
    };

    const transactionCanBeRefreshed = (transaction: Transaction) => {
        return (
            transaction.status !== TransactionStatus.Rejected &&
            transaction.status !== TransactionStatus.Expired &&
            transaction.status !== TransactionStatus.AwaitingDeposit &&
            transaction.status !== TransactionStatus.ActionInProgress &&
            transaction.status !== TransactionStatus.PendingApproval
        );
    };

    //const { company } = useContext(UserContext);
    const { getBalanceLabel } = useFormatting();

    return (
        <TableContainer>
            {props.transactions.length > 0 ? (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {t('date')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {t('description')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {t('status')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {t('approved_by')}
                            </TableCell>
                            <TableCell
                                sx={{ fontWeight: 'bold', textAlign: 'right' }}
                            >
                                {t('amount')}
                            </TableCell>
                            {props.companyPaymentDetails.paymentSource ===
                                paymentSources.instaPaidAccount && (
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}
                                >
                                    {t('balance')}
                                </TableCell>
                            )}
                            {props.companyPaymentDetails.paymentProvider ===
                                paymentProviders.aiia && (
                                <>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {t('refresh')}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {t('retry')}
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(props.transactions) &&
                            props.transactions.map((transaction) => (
                                <TableRow key={transaction.transactionId}>
                                    <TableCell
                                        sx={{
                                            fontWeight: FONT_WEIGHT.MEDIUM,
                                            color: getDateColor(
                                                transaction.status
                                            )
                                        }}
                                    >
                                        {formatDate(
                                            new Date(
                                                transaction.performedAt
                                                    ? transaction.performedAt
                                                    : transaction.createdAt
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontWeight: FONT_WEIGHT.MEDIUM }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Typography
                                            fontWeight={FONT_WEIGHT.MEDIUM}
                                            display={'flex'}
                                            fontSize={TEXT_SIZE.SMALL}
                                            whiteSpace={'nowrap'}
                                        >
                                            {transaction.transactionType}{' '}
                                            {transaction.userName && (
                                                <>
                                                    {' -  '}
                                                    <a
                                                        style={{
                                                            color: 'black',
                                                            marginLeft: '2px'
                                                        }}
                                                        href={`/users/${transaction?.userId}`}
                                                    >
                                                        {transaction.userName}
                                                    </a>
                                                </>
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontWeight: FONT_WEIGHT.MEDIUM }}
                                    >
                                        <Chip
                                            color={
                                                getStatusColor(
                                                    transaction.status
                                                ) as any
                                            }
                                            label={
                                                <Typography
                                                    fontWeight={
                                                        FONT_WEIGHT.MEDIUM
                                                    }
                                                    fontSize={TEXT_SIZE.SMALL}
                                                >
                                                    {
                                                        TransactionStatus[
                                                            transaction.status
                                                        ]
                                                    }
                                                </Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontWeight: FONT_WEIGHT.MEDIUM }}
                                    >
                                        {transaction.approvalType ===
                                            approvalTypes.manual && (
                                            <>
                                                {transaction.executantAdminName}
                                            </>
                                        )}
                                        {transaction.approvalType ===
                                            approvalTypes.automatic && (
                                            <>{t('automatic_approval')}</>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: 'right',
                                            fontWeight: FONT_WEIGHT.MEDIUM
                                        }}
                                    >
                                        {getBalanceLabel(
                                            transaction.amount,
                                            undefined ?? 'DKK',
                                            undefined,
                                            true
                                        )}
                                    </TableCell>
                                    {props.companyPaymentDetails
                                        .paymentSource ===
                                        paymentSources.instaPaidAccount && (
                                        <TableCell
                                            sx={{
                                                textAlign: 'right',
                                                fontWeight: FONT_WEIGHT.MEDIUM
                                            }}
                                        >
                                            {getBalanceLabel(
                                                transaction.balance,
                                                undefined ?? 'DKK',
                                                undefined,
                                                true
                                            )}
                                        </TableCell>
                                    )}

                                    {props.companyPaymentDetails
                                        .paymentProvider ===
                                        paymentProviders.aiia &&
                                    transactionCanBeRefreshed(transaction) ? (
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {transaction.status ===
                                                'Authorized' && (
                                                <IconButton
                                                    style={{ padding: 0 }}
                                                    title={t('refresh_status')}
                                                    onClick={() =>
                                                        onRefreshClick(
                                                            transaction.transactionId
                                                        )
                                                    }
                                                >
                                                    <RefreshIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            sx={{ textAlign: 'center' }}
                                        />
                                    )}

                                    {props.companyPaymentDetails
                                        .paymentProvider ===
                                    paymentProviders.aiia ? (
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {transaction.status == 'Failed' && (
                                                <IconButton
                                                    style={{ padding: 0 }}
                                                    title={t('retry')}
                                                    onClick={() =>
                                                        onTryAgainClick(
                                                            transaction.transactionId
                                                        )
                                                    }
                                                >
                                                    <PlayIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            ) : (
                <Alert sx={{ marginTop: 1 }} severity="info">
                    {t('no_transactions')}
                </Alert>
            )}
        </TableContainer>
    );
};
